
import Hero from "../../images/Services/Qa/hero.svg";
import Postman from "../../images/Services/Qa/tech/postman.svg";
import Xcode from "../../images/Services/Qa/tech/xcode.svg";
import AndroidStudio from "../../images/Services/Qa/tech/android.svg";
import Figma from "../../images/Services/Qa/tech/figma.svg";
import Jira from "../../images/Services/WebTechnologies/tech/jira.svg";
import Redmine from "../../images/Technology/StackSection/redmine.svg";
import Project from "../../images/Services/Qa/keys/project.svg";
import TestEnv from "../../images/Services/Qa/keys/testEnv.svg";
import TestCase from "../../images/Services/Qa/keys/testCase.svg";
import TestExec from "../../images/Services/Qa/keys/testExec.svg";
import BugTracking from "../../images/Services/Qa/keys/bugTracking.svg";
import Verification from "../../images/Services/Qa/keys/verification.svg";
import OgImage from "../../images/Services/Qa/og_image.png";
import Prevents from "../../images/Services/Qa/necessary/prevents.svg";
import Protects from "../../images/Services/Qa/necessary/protects.svg";
import Saves from "../../images/Services/Qa/necessary/saves.svg";
import Ensures from "../../images/Services/Qa/necessary/ensures.svg";
import Improves from "../../images/Services/Qa/necessary/improves.svg";
import EndToEnd from "../../images/Services/Qa/services/endToEnd.svg";
import Perfomance from "../../images/Services/Qa/services/performance.svg";
import Mobile from "../../images/Services/Qa/services/mobile.svg";
import Security from "../../images/Services/Qa/services/security.svg";
import Automated from "../../images/Services/Qa/services/automated.svg";

const dataForQa = {
  service: "qa",
  ogImage: OgImage,
  hero: {
    title: "Software Quality Assurance (QA) Services",
    subtitle: "Don't let bugs cost you customers. ",
    description: "Order our QA services to let our experts catch issues before your users do!",
    img: Hero
  },
  services: {
    title: "Our QA services",
    description: "Our dedicated QA team steps in at any project stage to test your product thoroughly and catch bugs early. Our software QA testing services make sure your software works exactly as intended.",
    cases: [
      {
        title: "End-to-End Testing",
        description: "Think of this as taking your software on a complete test drive. We simulate real users clicking through your checkout process, filling out forms, and navigating between pages. When we tested an e-commerce platform, we caught a critical bug where orders weren't syncing with inventory - before it could affect real customers.",
        img: EndToEnd,
      },
      {
        title: "Performance Testing",
        description: "Ever wonder how your app performs when 10,000 users hit it at once? We find out. We simulate heavy traffic, measure response times, and identify breaking points. One client's payment system slowed to a crawl during Black Friday sales - until we spotted and fixed the database bottleneck during our stress tests.",
        img: Perfomance,
      },
      {
        title: "Mobile App Testing",
        description: "Your app needs to work flawlessly, whether it's on the latest iPhone or a three-year-old Android device. We test across 30+ popular devices and OS versions. Recently, we caught a critical UI bug that only appeared on tablets in landscape mode - saving our client from negative reviews.",
        img: Mobile,
      },
      {
        title: "Security Testing",
        description: "We think like hackers, so you don't have to. Our quality assurance services probe your system's defenses, checking everything from basic SQL injections to complex authentication bypasses. For a fintech client, we uncovered and patched a vulnerability that could have exposed sensitive transaction data.",
        img: Security,
      },
      {
        title: "Automated Testing",
        description: "Why run the same tests manually when machines can do it better? We build smart test automation that runs 24/7, catching bugs while your team sleeps. Our automated suite for a healthcare platform runs 1,000+ test cases in under 30 minutes - work that would take days to do by hand.",
        img: Automated,
      },
    ]
  },
  necessary: {
    title: "Why is QA testing necessary?",
    description: "The cost of fixing software bugs increases exponentially after release. Smart businesses order QA testing services early to protect their reputation, save money, and keep customers happy. Quality assurance isn't just a step - it's your shield against costly mistakes.",
    cases: [
      {
        title: "Prevents Revenue Loss",
        description: "Undetected bugs can paralyze critical business functions, halt sales, and drive customers away. Our systematic software QA services safeguard your revenue streams by catching issues before they reach your users and impact your business operations.",
        img: Prevents,
      },
      {
        title: "Protects Your Brand Reputation",
        description: "Software quality directly reflects on your brand. Users quickly lose trust after encountering bugs or crashes. Regular QA testing maintains your reputation by ensuring consistent, reliable performance across all features.",
        img: Protects,
      },
      {
        title: "Saves Time and Money",
        description: "Finding and fixing bugs during development is significantly cheaper than addressing them post-release. Early QA testing prevents costly emergency fixes, reduces development rework, and helps maintain efficient release schedules.",
        img: Saves,
      },
      {
        title: "Ensures Security Compliance",
        description: "Modern software must meet strict security standards and regulatory requirements. QA testing verifies your compliance with industry regulations, protects sensitive data, and builds a strong security foundation for your applications.",
        img: Ensures,
      },
      {
        title: "Improves User Experience",
        description: "Poor software performance frustrates users and increases abandonment rates. QA as a service helps create seamless, intuitive interfaces. It identifies usability issues, optimizes performance, and ensures consistent behavior across all platforms.",
        img: Improves,
      },
    ]
  },
  choose: {
    title: 'Why you should choose us',
    cases: [
      {
        number: "01",
        title: "Transparent Reporting and Communication",
        description: "We keep you in the loop with clear, detailed reports and real-time updates. No technical jargon - just straightforward insights about your project's quality status, with actionable recommendations you can actually understand.",
      },
      {
        number: "02",
        title: "Flexible Testing Team Scaling",
        description: "Whether you need two QA engineers or twenty, we quickly adjust our team size to match your project demands. Our specialists seamlessly integrate with your workflow, saving you the hassle of long-term hiring commitments.",
      },
      {
        number: "03",
        title: "Industry-Specific Testing Expertise",
        description: "Our QA teams specialize in your industry's unique requirements, from fintech security standards to healthcare data compliance. We've built deep knowledge across sectors, bringing relevant testing strategies to your specific needs.",
      },
      {
        number: "04",
        title: "Custom Testing Frameworks",
        description: "When providing software quality assurance services, it is unacceptable to use one-size-fits-all solutions. Instead, we build testing strategies tailored to your product. We choose the right tools and approaches based on your specific goals, technology stack, and business requirements.",
      },
      {
        number: "05",
        title: "Quick Project Launch",
        description: "Skip the lengthy setup phase. Our well-established testing processes and ready-to-go infrastructure mean we can start testing your software within days, not weeks or months.",
      },
    ]
  },
  keys: {
    title: "Steps of QA testing service",
    description: "",
    cases: [
      {
        title: "Project Analysis and Planning",
        description: "We dive deep into your requirements, documentation, and business goals. Our team maps out test scenarios, sets quality benchmarks, and creates a detailed testing strategy aligned with your development timeline.",
        number: "01",
        img: Project,
      },
      {
        title: "Test Environment Setup",
        description: "Our team builds the right testing environment for your project. When providing software quality assurance testing services, we configure all necessary tools and prepare test data. We also ensure our infrastructure matches your production setup for accurate results.",
        number: "02",
        img: TestEnv,
      },
      {
        title: "Test Case Development",
        description: "We create comprehensive test cases covering all critical features and user paths. Each test case is designed to verify functionality, uncover potential issues, and ensure your software meets quality standards",
        number: "03",
        img: TestCase,
      },
      {
        title: "Test Execution",
        description: "Our QA specialists run manual and automated tests according to the strategy. We test across different environments, devices, and conditions to ensure thorough coverage and reliable results.",
        number: "04",
        img: TestExec,
      },
      {
        title: "Bug Tracking and Reporting",
        description: "When providing software testing and quality assurance services, we document all findings in detail, with clear steps to reproduce issues. Each bug report includes severity levels, screenshots, and recommendations for fixes.",
        number: "05",
        img: BugTracking,
      },
      {
        title: "Verification and Sign-off",
        description: "After fixes are implemented, we verify each solution and run regression tests to ensure new changes haven't affected existing functionality.",
        number: "06",
        img: Verification,
      },
    ]
  },
  technologies: {
    title: "Tech Stack That We Use",
    techData: {
      tools: [Postman, Xcode, AndroidStudio, Figma, Jira, Redmine]
    },
  }
}

export default dataForQa
