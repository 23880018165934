import React from "react";
import SEO from "../../components/CommonComponents/SEO";
import Layout from "../../components/CommonComponents/Layout";
import Services from "../../components/SingleService/Qa/Services";
import Choose from "../../components/SingleService/Qa/Choose"
import dataForQa from "../../information/Services/dataForQa";
import { mainUrl } from "../../js/config";
import Hero from "../../components/SingleService/Qa/Hero";
import Experience from "../../components/SingleService/Qa/Experience";
import Necessary from "../../components/SingleService/Qa/Necessary/Necessary";
import Keys from "../../components/SingleService/Qa/Keys";
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise";
import Cases from "../../components/CommonComponents/Cases";
import Technologies from "../../components/SingleService/Qa/Technologies";
import dataForProjects from "../../information/CloudSolutions/dataForProjects.json";

import "../../components/SingleService/Startups/StartupsRoadmap.scss"


const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function Startups() {
  const { ogImage, hero, services, necessary, choose, keys, technologies } = dataForQa;
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";
  return (
    <section className="startup">
      <SEO
        title="Software Quality Assurance Testing Services | OS-System"
        description="Professional QA services to ensure your software works flawlessly. Get thorough testing, detailed reports, and expert quality assurance from OS System's dedicated team."
        canonical={`${mainUrl}/services/qa/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={50}>
        <Hero data={hero} />
        <Services data={services} />
        <Experience additionalWord="& QA engineers" />
        <Necessary data={necessary} />
        <Choose data={choose} />
        <Keys data={keys} />
        <Technologies data={technologies} isCompact={true} />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
          customClass="cloud"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </section>
  )
}
